.product_modal_main {
	margin: 20px 20px;
	width: 95%;
	height: 64px;
	padding: 0 20px;
	background: #ffffff;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #f4f5f7;
	box-shadow: 0px 0px 30px rgba(214, 215, 226, 0.3);
	margin-bottom: 10px;
}

.product_modal_image {
	border: 4px solid #ecf0f3;
	border-radius: 4px;
	height: 42px;
	width: 42px;
}
.product_modal_title {
	margin-left: 5px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */

	color: #464e5f;
	margin-bottom: 0;
}
.product_modal_price {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */

	color: #1a5e8b;
	margin-bottom: 0;
}
.product_modal_price_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	/* identical to box height */

	text-transform: uppercase;

	color: rgba(70, 78, 95, 0.5);
	margin-bottom: 0;
}
