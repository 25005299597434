/* Customize website's customScrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */

.customScrollbar,
body,
.ant-table-body {
  overflow-y: auto;
  box-sizing: content-box; /* Ensure padding is inside the box */
}

/* Default state: Transparent customScrollbar */
/* Set the width to the intended size */
.customScrollbar::-webkit-scrollbar,
body::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
.customSiderScrollbar::-webkit-scrollbar {
  width: 0px !important;
  height: 8px;
  background-color: transparent;
}

.customScrollbar::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: transparent;
  transition: all 0.3s ease-in-out !important;
}

.customScrollbar:hover::-webkit-scrollbar-thumb,
body:hover::-webkit-scrollbar-thumb,
.ant-table-body:hover::-webkit-scrollbar-thumb {
  /* Make thumb visible on hover */
  background-color: lightgray;
}
