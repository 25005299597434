.dashboard-container-main {
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  height: 465px;
  overflow-y: scroll;
}
.dashboard-container-main-customer {
  width: 100%;
  background: #ffffff;
  border-radius: 5px;

  height: 465px;
  overflow-y: scroll;
}
.main-dashboard {
  width: 100%;
  margin-bottom: 20px;
  max-width: 50%;
}

.card-main-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  height: 29px;
  color: #464e5f;

  margin-bottom: 16px;
}

.card-subHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #464e5f;
  margin-bottom: 5px;
}

.card-months {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #7e838f;
  margin-bottom: 23px;
}

.more-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: right;

  color: #1a5e8b;
}
