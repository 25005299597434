.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #ffffff14 !important;
}

.ant-menu-sub.ant-menu-inline {
  background: transparent !important;
}

.ant-menu-title-content {
  color: #fff;
  font-size: 16px;
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  color: #fff;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  color: #fff;
}
.ant-menu-item,
.ant-menu-submenu,
.ant-menu-submenu-title {
  padding: 0 !important;
}

.wh_16 {
  width: 16px;
  height: 16px;
}
.side_navigation_bar {
  width: 20%;
  background: #1a5e8b;
  top: 87px;
}

.side-navigation-main-div {
  background: #1a5e8b !important;
}

.side-bar-menu {
  position: sticky;
  top: 0px;
  border-right: 0px !important;
}

.nav_item > a {
  display: block;
  padding-left: 16px;
  text-decoration: none;

  color: #ffffff;
}
.active {
  background: rgba(255, 255, 255, 0.08);
}
.nav_item {
  display: flex;
  align-items: flex-end;

  padding: 16px 16px;

  text-decoration: none;
}
.link_tag {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.m {
  margin: 24px 16px 20px;
}
.search {
  border: none;
  background: none;
  margin-left: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #ffffff;

  opacity: 0.6;
}

::-webkit-input-placeholder {
  /* Edge */

  color: #ffffff;
}

:-ms-input-placeholder {
  /* Internet Explorer */

  /* identical to box height */

  color: #ffffff;
}

::placeholder {
  color: #ffffff;
}
