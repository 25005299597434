.bundles-card-main {
	display: flex;
	background: white;
	width: 360px;
	padding: 16px;
	margin-bottom: 16px;
	margin-right: 16px;
	height: 72px;
	border-radius: 4px;
	box-shadow: 0px 0px 30px rgba(214, 215, 226, 0.3);
}

.bundles-card-title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	color: #464e5f;
}
.bundles-card-subTitle {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */

	color: rgba(70, 78, 95, 0.6);
}
.bundles-card-image-container {
	height: 40px;
	width: 40px;
	border-radius: 10px;
	border-width: 5px;
	overflow: hidden;
	display: flex;
	align-items: center;
	margin-right: 10px;
}
