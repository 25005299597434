.borderd-div {
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 400px;
  border: 2px solid #e7e9ec;
  border-radius: 5px;
}

.label-in-border {
  margin-left: 5px;
  background: white;
  margin-top: -10px;
}

.borderd-div-state {
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 220px;
  border: 2px solid #e7e9ec;
  border-radius: 5px;
}

.calender-top-buttons {
  width: 73px;
  height: 28px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  background: #1a5e8b;
  border-radius: 4px;
}

.outlined-box {
  background-color: #ffffff;
  border: 1px solid #1a5e8b;
  border-radius: 8px;
  padding: 5px;
  width: 173px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  font-family: Arial, sans-serif;
  font-size: 11px;
  color: #000;
  display: none;
}

.arrow {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #1a5e8b;
}

.applyBtn:hover .outlined-box {
  display: block;
}
