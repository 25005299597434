.cusRelative {
  position: relative;
}

.cuStoreName {
  align-items: center;
  gap: 12px;
  display: flex;
  justify-content: end;
}

.lastSyncText {
  margin-top: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #7e838f;
  margin-bottom: 0px;
}

.cu-header {
  background-color: #fff !important;
  height: 55px !important;
  padding: 2px 24px !important;
  position: sticky;
  top: 0;
  z-index: 2;
}

.row-cus-align-center {
  align-items: center;
}

.store_name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-transform: capitalize;
  text-align: right;
  letter-spacing: 0.01em;

  color: #464e5f;
}

.cusAvatar svg {
  height: 20px;
  stroke: 1;
}
