.suggestion_main_div {
	padding: 25px 0px 41px 0px;
}
.section_main_heading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	width: 48%;
	color: #464e5f;
	margin-bottom: 16px;
}
.bcg {
	background: #cf7f00;
	border-radius: 4px;
	padding: 4px 7px;
	color: #fff;
}
.flx_space_btw {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 5px 0px 5px;
}

.products {
	width: 100%;
	margin: 0 auto;
	padding: 0px 25px;

	border: 1px solid red;
}
.row {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.padd {
	gap: 13px;
}
.col-24 {
	width: 24%;
	padding: 14px 16px 17px;
	background: #ffffff;
	box-shadow: 0px 0px 20px rgb(222 222 222 / 3%);
	border-radius: 5px;
}
.price_with_button {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}
.heading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 5px;
	/* identical to box height */
	color: #d3602a;
}
.description {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */
	color: #505255;
	margin-bottom: 12px;
}
.price {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	text-transform: capitalize;
	color: #464e5f;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */
	padding-top: 4px;
	color: #7e838f;
}
.mb-23 {
	margin-bottom: 23px;
	padding-left: 24px;
	padding-top: 3px;
}

.btn_green {
	width: 50px;
	height: 19px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 13px;
	border: none;

	color: #ffffff;
	background: #4ab58e;
	border-radius: 4px;
}
.btn_pink_red {
	width: 50px;
	height: 19px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 13px;
	border: none;

	color: #ffffff;
	background: #f64e60;
	border-radius: 4px;
}

.card_para {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	/* or 150% */
	color: #7e838f;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
