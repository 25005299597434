/* heading */
.heartGraphMainDiv .g2-html-annotation:nth-child(2),
.circleGraphMainDiv .g2-html-annotation:nth-child(2),
.startGraphMainDiv .g2-html-annotation:nth-child(2) {
  top: 115px !important;
}

/* value */
.heartGraphMainDiv .g2-html-annotation:nth-child(3),
.circleGraphMainDiv .g2-html-annotation:nth-child(3),
.startGraphMainDiv .g2-html-annotation:nth-child(3) {
  top: 110px !important;
}

.circleGraphMainDiv {
  overflow: hidden !important;
}

.circleGraphMainDiv canvas {
  height: 270px !important;
}
