.region-card-main {
	display: flex;
	width: 353px;
	background: white;
	height: 105px;
	border-radius: 4px;
	align-items: center;
	padding-block: 19px;
	margin-right: 16px;
	margin-bottom: 16px;
	padding-right: 19px;
}
.region-card-order-div {
	min-width: 137px;
	max-width: 137px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 40%;
	border-right: 1px solid #f4f5f7;
	height: 90px;
}
.region-card-order-number {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 25px;
	line-height: 43px;
	/* identical to box height */
	text-align: center;
	color: #1a5e8b;
	margin-bottom: 0;
}

.text-size-11 {
	font-size: 11px;
}

.region-card-order-text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	/* identical to box height */

	text-align: center;
	text-transform: uppercase;

	color: rgba(70, 78, 95, 0.5);
	margin-bottom: 0;
}

.region-card-key {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */

	text-transform: uppercase;

	color: rgba(70, 78, 95, 0.5);
	margin-bottom: 10px;
}
.region-card-value {
	width: 100px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;

	color: #464e5f;
	margin-bottom: 10px;
	text-transform: capitalize;
	white-space: nowrap;
	overflow: hidden;
}
