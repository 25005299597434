.bg-color {
	background: #91d5ff;
	border-radius: 5px;
	border: 2px solid #91d5ff;
}

.flx {
	background: #e6f7ff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 28px 0px 16px;
	margin-left: 6px;
}

.width-50 {
	width: 50%;
	display: flex;
	gap: 13px;
	align-items: center;
	height: 68px;
}

.data_uploading {
	font-weight: 700;
	font-size: 15px;
	line-height: normal;
	color: #464e5f;
	margin-bottom: 5px;
}

.dataText {
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #7e838f;
	margin: 0;
}

.pdt-22 {
	padding-top: 30px;
}

.see_more {
	display: flex;
	align-items: center;
	gap: 3px;
	border: none;
	background: none;
	font-weight: 400;
	font-size: 12px;
	/* line-height: 15px; */
	text-align: right;
	cursor: pointer;
	color: #1a5e8b;
}

.is-cursor-pointer {
	cursor: pointer;
}

.date {
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	text-align: right;
	color: #7e838f;
}
