.payment-card-main {
  width: 400px;
  height: 630px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(20, 20, 43, 0.08);
  border: 1px solid #eff0f7;
  padding: 32px 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 24px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-card-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  /* identical to box height, or 109% */

  color: #d3602a;
}
.payment-card-desc {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  color: rgba(70, 78, 95, 0.7);
}
.payment-card-price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 66px;
  /* identical to box height, or 122% */

  color: #464e5f;
}

.payment-card-period {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  /* identical to box height, or 110% */

  color: rgba(70, 78, 95, 0.7);
}
.payment-card-second-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  color: #464e5f;
  margin-bottom: 17px;
}
.payment-card-items {
  margin-left: 4px;
  margin-bottom: 0%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.payment-card-button {
  text-align: center;
  background: #1181cb;
  width: 215px;
  height: 50px;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: 4px solid #1a5e8b;
}
.special-plan-card-button {
  text-align: center;
  background: #1a5e8b;
  width: 308px;
  height: 72px;
  border-radius: 96px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  padding-top: 25px;
  /* identical to box height, or 111% */

  text-align: center;

  /* Neutral/100 */

  color: #ffffff;
}
.current-payment-card-main {
  width: 400px;
  height: 630px;
  border-radius: 24px;
  background: #2a85a9;
  box-shadow: 0px 2px 12px rgba(20, 20, 43, 0.08);
  border: 1px solid #eff0f7;
  padding: 32px 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 24px;
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.current-payment-card-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  /* identical to box height, or 109% */

  color: #ffffff;
}
.current-payment-card-desc {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  /* Neutral/400 */

  color: #d9dbe9;
}
.current-payment-card-price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 66px;
  /* identical to box height, or 122% */

  color: #ffffff;
}

.current-payment-card-period {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  /* identical to box height, or 110% */

  /* Neutral/400 */

  color: #d9dbe9;
}
.current-payment-card-second-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  color: #ffffff;
  margin-bottom: 17px;
}
.current-payment-card-items {
  margin-left: 4px;
  margin-bottom: 0%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #d9dbe9;
}

.current-payment-card-button {
  text-align: center;
  background: #ffffff;
  width: 308px;
  height: 65px;
  border-radius: 96px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  text-align: center;

  /* Neutral/100 */

  color: #1a5e8b;
}
