body {
  font-size: 12px !important;
}

.ant-layout {
  background: #f3f5f7 !important;
}

.ant-layout-header {
  padding: 0 15px !important;
}

.site-layout-background,
.ant-layout-header {
  background: #f3f5f7;
}

/* hde blue small border for nav selected */
.ant-menu-item:after,
.ant-menu-submenu:after {
  border: none !important;
}

/* change the bk color of nav */
.ant-layout-header,
.ant-menu-horizontal,
.ant-menu.ant-menu-root {
  background: var(--theme-color) !important;
}

.ant-table-thead th.ant-table-cell {
  background: var(--table-header-color) !important;
  color: var(--table-header-text-color) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  height: 47px !important;
}

.ant-table-thead tr :first-child {
  border-top-left-radius: 8px;
}
.ant-table-thead tr :last-child {
  border-top-right-radius: 8px;
}
/* make nav link bolder */
.ant-menu-title-content,
.ant-menu-title-content > a {
  color: #fff !important;
  font-weight: bold;
  font-size: 16px;
}

.CardCarousel > .slick-list {
  padding: 10px 50px !important;
}

/* aleft nav kay kuch items bahir arahay thay sub ki width samekrdi */
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100% !important;
}

/* change the bk color of selected nav and border styling */
.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child,/*ya class for single parent and for selected child also*/
.ant-menu-submenu-selected /*ya us nav kay liya jiskay child han or koi child select hoa ha*/ {
  background: rgba(255, 255, 255, 0.08) !important;
  border-top-right-radius: 0;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  color: #fff !important;
}
.ant-menu-submenu-arrow {
  color: #fff !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-arrow {
  color: #fff !important;
}
/* overwriting the css done above for the  */
.ant-menu-sub.ant-menu-inline > .ant-menu-item-selected {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--theme-hoverPrimary-color) !important;
}

/* removing space between header and sidebar  */
.ant-menu-submenu-title,
.ant-menu-item {
  margin: 0 !important;
}

/* .ant-layout-sider.ant-layout-sider-light :hover {
  flex: 0 0 194px !important;
  min-width: 194px !important;
}
.ant-layout-sider.ant-layout-sider-light {
  flex: 0 0 50px !important;
  min-width: 49px !important;
  z-index: 20;
} */

/* setting background for the child option of navbar */
.ant-menu-item.ant-menu-item-only-child,
.ant-menu-submenu-arrow {
  background: var(--theme-color) !important;
  margin: 0 !important;
}

/* changing padding for childrend navs */
.ant-menu-sub > .ant-menu-item.ant-menu-item-only-child {
  background: var(--theme-color) !important;
  padding-left: 60px !important;
}

/* selected single parent or parent ka text ka color rakh rahas */
.ant-menu-item-selected a,
.ant-menu-submenu-selected span {
  color: #fff !important;
}

/* selected child ka background ka color rakh raha */
.ant-menu-submenu-selected
  ul.ant-menu-sub
  .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background: rgba(255, 255, 255, 0.08) !important;
}

/* selected child ka text ka rakh raha */
.ant-menu-submenu-selected ul.ant-menu-sub .ant-menu-item-selected a {
  color: #fff !important;
}

/* styling for pagination for antd table */
.ant-pagination-item-active {
  background: #1890ff !important;
}
.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination-mini .ant-pagination-item,
.ant-pagination-mini .ant-pagination-mini {
  padding: 14px 8px !important;
  background: #fff !important;
  margin: 0 2px !important;
  border: 1px solid #d9d9d9 !important;
}

.ant-pagination-mini .ant-pagination-prev svg,
.ant-pagination-mini .ant-pagination-next svg {
  width: 20px !important;
  height: 20px !important;
}

.ant-pagination-mini .ant-pagination-item a {
  line-height: 5px;
}

.ant-pagination-mini .ant-pagination-item-active {
  background: #1890ff !important;
}
/* styling for pagination for antd table */

.ant-skeleton-image {
  width: 60px !important;
  height: 60px !important;
}

/* overriding the antD table styling to show bullets within the table */
.ant-table ul {
  list-style: inside !important;
}

.LoadingGraphContainer > .ant-skeleton > .ant-skeleton-image {
  width: 300px !important;
  height: 100% !important;
  background: #fff;
}

.graphModal .ant-modal-body {
  min-height: 500px !important;
  padding-top: 10px !important;
}
/* .ant-picker-datetime-panel .ant-picker-time-panel {
	display: none;
} */

.ant-picker-panel-container {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.ant-select-selection-item {
  text-transform: capitalize !important;
}
.noOfOrders .ant-select-selection-item {
  text-transform: none !important;
}

.ant-table-cell-fix-left:has(.freezed-column) {
  box-shadow: 3px 21px 4px lightgrey;
}
.ant-menu-sub.ant-menu-inline {
  background: transparent !important;
}

/* Ant Drawer Styling*/

.ant-drawer-body {
  padding: 0 !important;
}

.ant-pagination-item-link {
  border: none !important;
  background: none !important;
}
.ant-pagination-item {
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  /* identical to box height */

  color: #464e5f !important;
  background: none !important;
  background-color: none !important;
  border: none !important;
}

.ant-pagination-item-active {
  background: #1a5e8b !important;
  border-color: #1a5e8b !important;
}

.ant-pagination-prev {
  line-height: 0 !important;
}
.ant-pagination-next {
  line-height: 0 !important;
}

/* overriding ant modal header to make it similar as per design*/
.ant-modal-header {
  background: #1a5e8b !important;
}
.ant-modal-title {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  /* identical to box height */

  display: flex !important;
  align-items: center !important;

  color: #ffffff !important;
}

/*updata styling of antrange picker*/

.ant-picker-panel-container {
  width: 600px;
}

.ant-picker-active-bar {
  background: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: #fff !important;
  background: #cf7f00 !important;
  border-radius: 20px !important;
  border-color: white;
}

.ant-picker-panel-container .ant-picker-panels {
  justify-content: space-between !important;
}
.ant-picker-date-panel .ant-picker-content {
  width: 270px !important;
}
.ant-picker-date-panel {
  width: 300px !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: #cf7f0014 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):before {
  background: #cf7f0014 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border: none !important;
}

.valuable-customer-main .sortBy-main .ant-checkbox-input {
  border-radius: 50% !important;
}

.ant-popover-inner-content:has(> .popover-child) {
  padding: 0 !important; /* styles to apply to the li tag */
  border-top-left-radius: 0.25rem !important; /* 4px */
  border-top-right-radius: 0.25rem !important; /* 4px */
}
.ant-popover-inner {
  /* styles to apply to the li tag */
  border-radius: 12px !important; /* 4px */
}

.text-3xl {
  font-size: 25px !important;
  font-weight: 700;
  color: #464e5f;
}

.antd-tag-override {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  margin-top: 10px !important;
}
.antd-tag-override > .ant-tag-close-icon {
  margin-left: 13px;
}

.ant-btn .anticon svg {
  vertical-align: initial;
}
.exportData .ant-btn:disabled {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
}
.ordersPerCustomer .ant-input-number {
  border-left: 0;
}
.ant-tree-node-content-wrapper-open {
  font-weight: bold;
}
