.table-data {
	height: 142px;
	width: 363px;
	/* left: 24px; */
	/* top: 24px; */
	background: #ffffff;
	border: 1px solid #f4f5f7;
	box-shadow: 0px 0px 30px rgb(214 215 226 / 30%);
	border-radius: 6px;
}
