.dashboardLineGraph,
.dashboardPieChart .dashboardTable {
  height: 450px;
}

.dashboardLineGraph canvas,
.dashboardPieChart canvas {
  height: 380px !important;
}

.dashBoardLiquidChart canvas {
  height: 250px !important;
}
.cards-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.mini-card {
  max-width: 100px;
  overflow: hidden;
  text-align: center;
}

.mini-card-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #464e5f;
}
.mini-card-value:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}
.mini-card-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-top: 12px;
  margin-bottom: 2px;
  /* identical to box height */

  color: rgba(70, 78, 95, 0.4);
}
.customer-card-left-container {
  display: flex;
  /* width: 100px; */
  /* justify-content: space-around; */
  align-items: center;
}
.customer-card-right-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  /* width: 100px; */
  /* justify-content: space-around; */
}
.customer-card-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  padding: 2px;
}
.customer-card-main-container-no-border {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2px;
}
.customer-card-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin-bottom: 4px;
  color: #464e5f;
  width: 180px;
  word-wrap: break-word; /* Break long words if needed */
  word-break: break-word; /* for older browsers */
  overflow-wrap: break-word; /* long text wraps */
  white-space: normal;
}
.customer-card-email {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: rgba(70, 78, 95, 0.5);
}

.customer-card-numbers {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: right;

  color: #d3602a;
  margin-bottom: 5px;
}
.customer-card-unit {
  font-family: Montserrat !important;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  text-align: right;
  text-transform: uppercase;
  color: rgba(70, 78, 95, 0.5);
  margin-bottom: 0;
}
.customer-card-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  /* identical to box height, or 20px */
  margin-bottom: 0px;
  color: #464e5f;
  background: #fafcfc;
}
.dashboard-graph {
  height: 295px !important;
}
.dashboard-graph-pie {
  height: 300px !important;
  width: 300px !important;
}
.dashboard-table {
  height: 250px !important;
}
.pie-city {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  letter-spacing: 0.02em;

  color: #464e5f;
}
.pie-unit-sold {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #464e5f;
}
.pie-stats {
  width: 410px;
  background: #fafcfc;
  border: 1px dashed rgba(70, 78, 95, 0.1);
}

.filter-css {
  padding: 15px 15px;
  background: #ffffff;
  border: 1px dashed #ecedef;
  box-shadow: 0px 0px 20px rgba(222, 222, 222, 0.55);
  border-radius: 5px;
  height: 75px;
  display: flex;
  align-items: center;
}
.filter-css-short {
  padding: 20px;
  background: #ffffff;
  height: 75px;
  width: 432px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.filter-css-wo-comparison {
  padding: 20px;
  background: #ffffff;
  border: 1px dashed #ecedef;
  box-shadow: 0px 0px 20px rgba(222, 222, 222, 0.55);
  border-radius: 5px;
  height: 75px;
  width: 900px;
  display: flex;
  align-items: center;
}

.pagination-container {
  background: #fafcfc;
  box-shadow: inset 0px -1px 0px rgba(70, 78, 95, 0.1);
  border-radius: 5px 5px 0px 0px;
  padding: 5px 17px;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}

.stocksTable {
  padding: 10px 0px;
  background: #ffffff;
  max-height: 700px;
}

.table-title-row {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #464e5f;
  margin-bottom: 0;
}

.valueable-sort {
  background: #fafcfc;
  display: flex;
  align-items: center;

  /* height: 32px;
	width: 148px; */
  width: 200px;
  left: 24px;
  top: 24px;
  border-radius: 4px;
  padding: 14px;
  background: #fafcfc;
  border: 1px dashed rgba(70, 78, 95, 0.06);
  box-shadow: inset 0px -2px 0px #d3602a;
  border-radius: 4px;
}
.valueable-sort-orders {
  background: #fafcfc;
  display: flex;
  align-items: center;

  /* height: 32px; */
  width: 200px;
  left: 24px;
  top: 24px;
  border-radius: 4px;
  padding: 14px;
  background: #fafcfc;
  border: 1px dashed rgba(70, 78, 95, 0.06);
  box-shadow: inset 0px -2px 0px #1a5e8b;
  border-radius: 4px;
}

.sortBy-main {
  display: flex;
  gap: 20px;
  width: 100%;
  margin: 0px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #464e5f;
}

.filter-and-sort-main-div {
  display: flex;
}

.stats-card-aov {
  width: 200px;
  border-radius: 18px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(20, 20, 43, 0.08);
  border: 1px solid #eff0f7;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.stats-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  /* identical to box height, or 109% */
  text-align: center;

  color: #d3602a;
}
.info:hover {
  cursor: pointer;
}
.stats-value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  /* identical to box height, or 122% */
  text-align: center;

  color: #464e5f;
  margin-bottom: 0;
}
.stats-desc {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  color: #464e5f;
}

.stats-card-product {
  width: 500px;
  height: 200px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(20, 20, 43, 0.08);
  border: 1px solid #eff0f7;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tooltip-content {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* or 140% */

  color: #464e5f;
}
.tooltip-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  color: #ffffff;
}

@media screen and (max-width: 1560px) {
  .customer-card-title {
    width: 155px;
  }
}
