.customers-card-main {
  display: flex;
  background: white;
  width: 489px;
  padding: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  height: 72px;
  border-radius: 4px;
  box-shadow: 0px 0px 30px rgba(214, 215, 226, 0.3);
}
.customers-card-main-frequent {
  display: flex;
  background: white;
  width: 345px;
  padding: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  height: 65px;
  border-radius: 4px;
  box-shadow: 0px 0px 30px rgba(214, 215, 226, 0.3);
}
.customers-card-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #464e5f;
}
.cutomer-name-wrap {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.customers-card-email {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  color: rgba(70, 78, 95, 0.5);
}
.customers-card-revenue {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: right;

  color: #d3602a;
}
.customers-card-revenue-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
}
.customers-card-order {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #1a5e8b;
}
.customers-card-order-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  text-transform: uppercase;

  color: rgba(70, 78, 95, 0.5);
}
