@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./styles/antd.css);
@import url(./styles/payment.css);
@import url(./styles/dashboard.css);
@import url(./styles/liquidGraphs.css);
@import url(./styles/topNotificationBar.css);
@import url(./styles/header.css);
@import url(./styles/progressBar.css);
@import url(./styles/sideMenu.css);
@import url(./styles/sugesstions.css);
@import url(./styles/dashboardContainer.css);
@import url(./styles/customer.css);
@import url(./styles/bundle.css);
@import url(./styles/regionCard.css);
@import url(./styles/sideModal.css);
@import url(./styles/paymentPlanCard.css);
@import url(./styles/productModal.css);
@import url(./styles/filter.css);
@import url(./styles/cardLoader.css);
@import url(./styles/loader.css);
@import url(./styles/customScrollbar.css);

.root,
#root,
#docs-root,
:root {
  /* --theme-color: #80d9e0;
  --theme-hoverPrimary-color: #31a7b1; */
  --theme-color: #1a5e8b;
  --theme-hoverPrimary-color: #161f83;
  --theme-light-color: #4768e9; /* #3c50e1 5464de 4768e9*/
  --text-primary-color: #3f6212;
  --table-header-color: #e9ecef;
  --table-header-text-color: #464e5f;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}
fieldset {
  border: 1px solid #000;
}
* {
  font-family: "Montserrat" !important;
  src: url("../src/assets/fonts/Montserrat-Regular.ttf");
}

body {
  margin: 0;
}

@font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat-Regular.ttf");
}

th {
  font-weight: bolder;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.payment-failed {
  background-image: url("../src/assets/images/payment-failed.jpg");
  background-size: cover;
}
.uninstalled {
  background-image: url("../src/assets/images/uninstalled.jpeg");
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}

.renew-plan {
  background-image: url("../src/assets/images/renew-plan.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}

/* New UI pages Css */
.subscription-alert {
  display: flex;
  max-width: 498px;
  width: 100%;
  margin: 0 auto;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  border-left: 8px solid #91d5ff;
  height: 90px;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  box-shadow: 0px 6px 10px 0px rgba(206, 209, 219, 0.35);
}

.subscription-alert > .image {
  margin-left: 20px;
}

.subscription-alert > .text > .heading {
  margin: 0;
  color: #464e5f;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
}

.subscription-alert > .text > .small-text {
  margin: 0;
  color: #464e5f;
  font-size: 12px;
  line-height: 1;
}
.subscription-card-loading-text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
}

.subscription-card {
  max-width: 498px;
  margin: 0 auto;
  border: 1px solid #eff0f7;
  background: #fff;
  border-radius: 24px;
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
  margin-top: 100px;
}

.subscription-card > .inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
  min-height: 408px;
}

.card-text > .title {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
  color: #464e5f;
  padding-top: 20px;
}

.card-text > .paragraph {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #464e5f;
  margin-top: 20px;
}
