.wrapper {
  max-width: 100%;
  overflow: hidden;
}

.movingDiv {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: movingDivAnimation 45s linear infinite;
}

.movingDiv p {
  display: inline-block;
}

@keyframes movingDivAnimation {
  0% {
    transform: translate3d(90%, 0, 0);
  }
  100% {
    transform: translate3d(-99%, 0, 0);
  }
}
