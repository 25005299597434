.side-modal-main {
  display: flex;
  padding: 27px;
  align-items: center;
  background: #1a5e8b;
}

.side-modal-footer {
  display: flex;
  padding: 27px;
  align-items: center;
  background: #fafcfc;
  height: 40px;
  justify-content: flex-end;
}
.side-modal-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-left: 19px;

  color: #ffffff;
}
.side-modal-vendortext {
  font-family: "Montserrat !important";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* identical to box height, or 171% */
  color: #464e5f;
}
.side-modal-vendorHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height, or 200% */

  text-transform: uppercase;
  color: #cf7f00;
}
.side-modal-checkbox-container {
  display: flex;
  flex-wrap: wrap;
  /* border-bottom: 1px solid rgba(70, 78, 95, 0.2); */
  padding-bottom: 25px;
  margin-bottom: 25px;
  height: 100%;
  overflow: auto;
}
.side-modal-product-container {
  display: flex;
  flex-wrap: wrap;
}

.cancel-btn {
  width: 80px;
  height: 32px;

  border: 1px solid #cbced1;
  border-radius: 4px;
  text-align: center;
  margin-right: 8px;
}

.save-btn {
  padding: 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80px;
  height: 32px;
  background: #4ab58e;
  border-radius: 4px;
}

.save-btn-disabled {
  padding: 2px;
  width: 80px;
  height: 32px;
  background: #bcc0bf;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
